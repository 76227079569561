import React from 'react';
import ReactDOM from 'react-dom';
import singleSpaReact from 'single-spa-react';
import App from './App';
import config from '../package.json';
import sdk from '@hubportal/sdk';

const lifecycles = singleSpaReact({
  React,
  ReactDOM,
  rootComponent: App,
  errorBoundary(err: Error, info: React.ErrorInfo) {
    sdk.reportError(err, config.name, info);

    return (
      <div>
        <h1>Something went wrong.</h1>
      </div>
    );
  },
});

export const { bootstrap, mount, unmount } = lifecycles;
